@font-face {
	font-family: 'Laila VibrerLocal';
	src: url('../fonts/laila-regular-webfont.eot'),
	url('../fonts/laila-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/laila-regular-webfont.woff2') format('woff2'),
	url('../fonts/laila-regular-webfont.woff') format('woff'),
	url('../fonts/laila-regular-webfont.ttf') format('truetype'),
	url('../fonts/laila-regular-webfont.svg#lailaregular') format('svg') {
    font-weight: normal;
    font-style: normal; } }
